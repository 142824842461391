.btn-hover{
  transition: all .2s ease;
  &:hover{
    transition: all .2s ease;
    opacity: 0.7;
  }
}

.bgr-body-chat {
  background-color: #1f1f1f !important;
}
.chat-list {
  .mb-2 {
    margin-bottom: 0 !important;
  }
  .chat-item {
    cursor: pointer;
    user-select: none;
  }
  .chat-item.selected {
    background-color: #2e2e2e !important;
    border: 1px solid #e8e8e8;
  }
  .chat-online {
    position: absolute;
    bottom: 2px;
    right: 12px;
    background-color: #05d802;
    width: 7px;
    height: 7px;
  }
  .chat-offline {
    position: absolute;
    bottom: 2px;
    right: 12px;
    background-color: #afafaf;
    width: 7px;
    height: 7px;
  }

  .chat-online.head-messaging,
  .chat-offline.head-messaging {
    width: 10px !important;
    height: 10px !important;
  }

  .unread {
    .MuiBadge-anchorOriginTopRightRectangle {
      top: 10px;
      right: 13px;
    }
  }
}

.chat-menu {
  position: relative;
  box-shadow: 0px 2px 1px 0px #00000033, 0px 1px 1px 2px #00000024, 0px 1px 3px 3px #0000001f !important;
}

.msg-container {
  .msg-item.justify-content-end {
    .chat-item-attachment {
      display: flex;
      justify-content: end;

      .img-attachment {
        display: flex;
        justify-content: end;
      }
    }
  }
}

a.linkified {
  color: blue !important;
}

.message {
  display: flex;
  flex-direction: column;
}

.message .timestamp {
  display: flex;
  justify-content: center;
  color: #999;
  font-weight: 600;
  font-size: 10px;
  margin: 10px 0px;
  text-transform: uppercase;
}

.bubble-container {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.message .bubble-container {
  font-size: 14px;
  display: flex;
  text-align: justify;
}

.message.mine .bubble-container {
  justify-content: flex-end;

  a {
    color: #fff !important;
  }
}

.message.start .bubble-container .bubble {
  border-top-left-radius: 4px;
}

.message.end .bubble-container .bubble {
  border-bottom-left-radius: 4px;
}

.message.mine.start .bubble-container .bubble {
  margin-top: 10px;
  border-top-right-radius: 4px;
}

.message.mine.end .bubble-container .bubble {
  border-bottom-right-radius: 4px;
}

.message .bubble-container .bubble {
  margin: 1px 0px;
  background: #f4f4f8;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 75%;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.message.mine .bubble-container .bubble {
  background: #007aff;
  color: white;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.text-underline {
  text-decoration: underline;
}

.image-hover {
  opacity: 0.8;
}

.chat {
  background-color: #1f1f1f;
  .chat-wrapper {
    background-color: #1f1f1f;
    max-width: calc(100% - 50px);
    padding: 0 50px;
    margin: 0 auto;
  }
}

.title-chat {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 15px;
}

.chat-search-input {
  border: 1px solid #4b4b4b !important;
  border-radius: 16px;
  .MuiOutlinedInput-root {
    border-radius: 18px !important;
    img {
      padding-right: 12px;
    }
    input {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.408px;
      color: #ffffff;
    }
  }
}

.input-search {
  .chat-search-input {
    border: 1px solid #4b4b4b;
    border-radius: 16px;
  }
  .MuiOutlinedInput-root {
    border-radius: 18px !important;
    img {
      padding-right: 12px;
    }
    input {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.408px;
      color: #ffffff;
    }
  }
}

.icon-recent {
  width: 24px;
  height: 24px;
  background: #383600;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.sort-recent {
  > div {
    justify-content: flex-start !important;
  }
}

.chat-wrapper {
  .MuiPaper-elevation1 {
    box-shadow: initial !important;
  }
  .MuiPaper-root {
    background-color: transparent;
  }
}

.avatart-chat {
  width: 36px !important;
  height: 36px !important;
}
.border-avatart-chat {
  border: 1px solid #4b4b4b;
}

.column-chat {
  margin-top: 10px;
  background-color: #272727;
  border-radius: 8px;
  height: 300;
  overflow: auto;
  margin-bottom: 27px;
}

.box-item-chat:hover{
  transition: all .2s ease;
  // padding: 16px 12px;
  background-color: #343434;
  >div{
    transition: all .2s ease;
    padding: 0 6px !important;
  }
}

.box-item-chat {
  transition: all .2s ease;
  border-bottom: 1px solid #404040;
  height: 68px;
  // height: initial !important;
  .m-2 {
    margin: 0 !important;
    .person-info {
      height: 100% !important;
      .p-2 {
        padding: 16px 12px !important;
        height: 100% !important;
        align-items: center;
      }
    }
  }
  .box-item-chat_child {
    border: initial !important;
    height: 100% !important;
  }
}

.box-item-chat.contact {
  .m-2 {
    .person-info {
      .p-2 {
        padding: 16px 16px 16px 0 !important;
      }
    }
  }
}

.menu-sort-chat {
  span:nth-child(1) {
    color: #ffffff;
  }
}
.menu-dropdown {
  > div:nth-child(3) {
    top: 115px !important;
  }
}

::-webkit-scrollbar {
  display: none;
}

//chat-message
.header-info-item-chat {
  background-color: #2e2e2e !important;
}
.unborder-box-chat {
  background: #272727;
  .MuiPaper-rounded {
    border-radius: 0px;
  }
}

.box-icon-header {
  display: flex;
  align-items: center;
  gap: 26.57px;
  .unpadding {
    padding: 0 !important;
    &:hover{
      width: 30px;
      height: 30px;
      background-color: #666666;
    }
  }
}

.bg-chat {
  background: #272727 !important;
}

.chat-item {
  .contact-user-info {
    display: flex;
    align-items: center;
    gap: 14.25px;
    &:hover{
      opacity: 0.7;
    }
    .user-info {
      font-size: 14px;
      color: #ffffff;
      font-weight: 700;
      line-height: 24px;
    }
  }
  .message-info {
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    color: #9e9e9e;
  }
  .times-info {
    font-weight: 400;
    font-size: 13px;
    color: #9e9e9e;
    line-height: 24px;
  }
}

.btn-send {
  width: 38px;
  height: 38px;
  background: #fdf100;
  border-radius: 10px;
  border: transparent;
}

.btn-link {
  padding: 0 !important;

  > span:nth-child(1) {
    width: 50px;
    height: 46px;
    background: #383600;
    border-radius: 16px;
  }
}

.person-info-flat {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // gap: 28px;
}

.header-user-info {
  padding: 16px 24px !important;
}

.header-user-avatar {
  margin-left: 0 !important;
}
.write-message {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.08);
  // height: 80px;
  padding: 17px 24px;
}
.msg-input {
  background: #464646;
  border-radius: 16px;
  > div {
    padding-right: 4px !important;
    // height: 46px;
    > input {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #ffffff;
    }
  }
}

.msg-input.input_message {
  .MuiOutlinedInput-multiline {
    padding: 4px 14px;
    textarea {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #ffffff;
      padding-right: 10px;
    }
  }
}

.message-search-keyword {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100% !important;
  background-color: #272727 !important;
}

.rce-mbox-time {
  text-align: left;
  position: absolute;
  color: #9e9e9e;
  bottom: -26px;
  min-width: 200px;
  left: -7px;
  margin: 0 !important;
}

.rce-mbox {
  overflow: initial !important;
  background: #343436;
  border: 1px solid #4b4b4b;
  box-sizing: border-box;
  border-radius: 5px;
}

.rce-mbox.rce-mbox-right {
  background: #666666;
  border: 1px solid #545454;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: initial !important;

  .rce-avatar-container {
    border: 1px solid #fdf100;
    box-sizing: border-box;
    border-radius: 50%;
  }
}

.rce-mbox-right {
  position: relative;
  left: initial !important;
  right: 44px;
  .rce-mbox-time {
    right: -10px !important;
    left: unset !important;
    text-align: right;
  }

  .rce-avatar-container {
    position: absolute;
    left: initial !important;
    right: -57px;
    bottom: 0;
    top: -11px;
  }
  .rce-avatar-container.default {
    width: 30px;
    height: 30px;
  }
}

.rce-container-mbox {
  padding-bottom: 42px;
  margin-left: 24px;
  margin-right: 24px;
}

.rce-mbox {
  left: 41px;
  margin-left: 0 !important;
  margin-right: 0 !important;
  .rce-avatar-container {
    border-radius: 50%;
    border: 1px solid #4b4b4b;
    position: absolute;
    left: -50px;
    top: -4px;
    bottom: 0;
  }
}

.makeStyle-input-attachfile {
  margin: 0 0 0 10px !important;
}

.box-shawdow-top {
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.08);
  z-index: 9;
  border-bottom: 1px solid #4343433d;
}

.dialog-delete {
  div:nth-child(3) {
    > div:first-child {
      background-color: #1f1f1f;
      color: #fff;
      border-radius: 20px;
      width: 414px;
      border: 1px solid #a9a9a9a9;
      .popup-delete {
        padding: 16px 16px;
        .MuiButton-containedSecondary:hover {
          background: #f50057;
        }
      }
    }
  }
}

.popup-upload {
  > div:nth-child(3) {
    > div:first-child {
      background-color: #1f1f1f;
      color: #fff;
      border-radius: 20px;
      border: 1px solid #a9a9a9a9;
    }
  }
}

.input-field-upload {
  background: #4b4b4b !important;
  border-radius: 16px;
  label {
    color: #9e9e9e;
  }
  .MuiFilledInput-underline::before {
    border-bottom: unset !important;
  }
  .MuiFilledInput-underline::after {
    border-bottom: 0.5px solid #fdf100 !important;
    left: 10px;
    right: 10px;
    bottom: -2px;
  }
  input {
    color: #ffffff;
  }
}

.url-user-name {
  text-decoration: none;
  color: #ffffff;
  &:hover {
    color: #b2b2b2;
  }
}

.full-image-chat {
  border: 2px solid #4b4b4b;
}

.btn-delete {
  display: none;
}

.start-messaging.px-3 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.st-popup-delete{
  .MuiDialog-paper{
    top: -70px;
  }
}

.list-comment-search{
  overflow-y: auto;
}

.list-comment-search_item:hover{
  transition: all .2s ease;
  background-color: #343434;
  padding: 0 6px;
  border-radius: 4px;
}

.icon-chat{
  transition: 0.2s ease;
  &:hover{
    transition: 0.2s ease;
    width: 30px;
    height: 30px;
    background-color: #383600 !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: rgb(255, 255, 255) !important;
}