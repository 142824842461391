.msg-item {
  .rce-mbox {
    max-width: calc(100% - 150px) !important;
    min-width: 30px;
    // overflow: hidden;
    padding: 4px 8px;
    margin: 0;
    .left,
    .right {
      text-align: left;
    }
  }
  .rce-mbox-title.rce-mbox-title--clear {
    margin: unset;
  }
  .rce-mbox-time.rce-mbox-time-block {
    background: none;
    padding: 0;
  }
  .rce-mbox-left-notch,
  .rce-mbox-right-notch {
    display: none;
  }
}

.rce-mbox-photo {
  .rce-mbox-photo--img {
    img {
      // max-width: 460px;
      // min-width: 460px;
    }
  }
  .rce-mbox-text {
    text-align: left;
    width: 100% !important;
    padding: 0;
    max-width: none;
  }
}
.rce-mbox.rce-mbox-right {
  box-shadow: initial !important;
}

.has-photo {
  .rce-mbox-time-block {
    position: relative;
  }
  .rce-mbox-photo {
    img {
      width: 100%;
    }
  }
}

.rce-mbox-text {
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  &::after {
    content: unset !important;
  }
}
.msg-container {
  justify-content: space-between;
  overflow: auto;
  width: 100%;
  height: 100px;
  .ms-chat-items {
    background: #f5f5f5;
    .rce-container-mlist.react-message-list {
      overflow: auto;
      width: 100%;
    }
  }
}
.rce-mbox.message-focus {
  background-color: #6a7eee;
  color: #fff;
  box-shadow: initial !important;
  .rce-mbox-right-notch,
  .rce-mbox-left-notch {
    fill: #6a7eee;
  }
  .rce-mbox-time,
  .rce-mbox-title {
    color: #fff;
  }
}

.rce-avatar-container.default {
  width: 30px;
  height: 30px;
}
.rce-mbox-body {
  .rce-avatar-container {
    img {
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .rce-mbox-file {
    padding: 0;
  }
  .rce-mbox-video,
  .rce-mbox-photo {
    margin: 0 !important;
  }
  .photo-chat-item {
    small {
      color: #299fee;
    }
  }
  .rce-mbox-photo {
    .rce-mbox-text::after {
      display: none !important;
    }
  }
  .url-chat-item {
    max-width: 460px;
    // margin: -5px;
    overflow: hidden;
    .url-chat-item-title {
      color: #299fee;
      text-decoration: none;
      font-weight: 600;
      font-size: 14px;
    }
    .url-meta {
      max-width: 460px;
    }
    a {
      font-size: 14px;
      text-decoration: none;
      color:#299fee;
    }
  }
}
.rce-mbox-video {
  max-width: 460px;
  .rce-mbox-text {
    max-width: 460px !important;
    text-align: left;
  }
}

.rce-mbox-reply-btn-left {
  display: none;
  // left: -50px; 
}
